<template scoped>
  <div>
    <el-form-item
      :rules="rules.checksum"
      label="Checksum"
      prop="operator.checksum.type"
      :style="{ marginTop: '10px' }"
    >
      <el-select
        v-model="logic.operator.checksum.type"
        :style="{ width: '100%' }"
        placeholder="Select the Checksum Method"
      >
        <el-option
          v-for="item in supportChecksum"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <div v-for="(val, index) in logic.operator.checksum.value" :key="index">
      <el-form-item
        :label="getLabel(index)"
        :prop="'operator.checksum.value.' + index + '.value'"
        :style="{ marginTop: '10px' }"
      >
        <el-row>
          <el-col :span="22">
            <el-input v-model="logic.operator.checksum.value[index].value">
              <el-switch
                slot="append"
                v-model="logic.operator.checksum.value[index].valueType"
                active-text="Value"
                active-value="Value"
                inactive-text="Variable"
                inactive-value="Variable"
              >
              </el-switch>
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-button
              @click="remove(index)"
              :style="{ marginLeft: '10px' }"
              circle
              type="danger"
              icon="el-icon-close"
            ></el-button>
          </el-col>
        </el-row>
      </el-form-item>

      <el-button
        v-if="index == logic.operator.checksum.value.length - 1"
        @click="add"
        icon="el-icon-circle-plus-outline"
        round
        :style="{ marginBottom: '10px', marginLeft: '180px' }"
        >Add</el-button
      >
    </div>

    <div v-if="logic.operator.checksum.value.length == 0">
      <el-form-item :label="getLabel(0)" :style="{ marginTop: '10px' }">
        <el-button
          @click="add"
          icon="el-icon-circle-plus-outline"
          round
          :style="{ marginBottom: '10px' }"
          >Add</el-button
        >
      </el-form-item>
    </div>

    <el-form-item
      :rules="rules.variable"
      label="Output Variable"
      prop="operator.checksum.outputVariable"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="logic.operator.checksum.outputVariable"
        placeholder=""
      >
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    logic: Object,
    endpoint: String,
  },
  created: function () {},
  computed: {},
  data: function () {
    return {
      supportChecksum: [
        {
          name: "CheckSum8 Xor",
          value: "CheckSum8Xor",
        },
        {
          name: "CheckSum8 Modulo 256",
          value: "CheckSum8Modulo256",
        },
        {
          name: "CheckSum8 2s Complement",
          value: "CheckSum82sComplement",
        },
        {
          name: "CRC-16",
          value: "CRC-16",
        },
        {
          name: "CRC-16-CCITT",
          value: "CRC-16-CCITT",
        },
        {
          name: "CRC-16-IBM",
          value: "CRC-16-IBM",
        },
        {
          name: "CRC-16-T10-DIF",
          value: "CRC-16-T10-DIF",
        },
        {
          name: "CRC-16-DNP",
          value: "CRC-16-DNP",
        },
        {
          name: "CRC-16-DECT",
          value: "CRC-16-DECT",
        },
        {
          name: "CRC-32",
          value: "CRC-32",
        },
        {
          name: "CRC-32C (Castagnoli)",
          value: "CRC-32C (Castagnoli)",
        },
        {
          name: "CRC-32K (Koopman)",
          value: "CRC-32K (Koopman)",
        },
        {
          name: "CRC-32Q",
          value: "CRC-32Q",
        },
      ],
      rules: {
        checksum: [{ validator: this.validator_checksum, trigger: "blur" }],
        variable: [{ validator: this.validator_variable, trigger: "blur" }],
      },
    };
  },
  methods: {
    getLabel: function (index) {
      if (index == 0) {
        return "Input Variable";
      }
      return "";
    },
    remove: function (index) {
      if (index > -1) {
        this.logic.operator.checksum.value.splice(index, 1);
      }
    },
    add: function () {
      this.logic.operator.checksum.value.push({
        operator: null,
        valueType: "Value",
        value: "",
      });
    },
    validator_checksum: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the checksum type should not be empty"));
      } else {
        callback();
      }
    },
    validator_variable: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the variable should not be empty"));
      } else {
        callback();
      }
    },
  },
};
</script>